import styled from 'styled-components'
import { colors, typography } from '../../styles/utilities/variables'

const StyledText = styled.p`
  color: ${props => (props.color ? colors[props.color] : colors.black)};
  font-size: ${props =>
    props.size ? typography.sizes[props.size] : typography.sizes.base};
  text-align: ${props => (props.center ? 'center' : 'left')};

  a:not([class]) {
    color: ${colors.primary};
    border-bottom: 1px solid ${colors.primary};
    width: fit-content;
  }
`

export default StyledText
