import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import StyledOverlay from './style';

const Overlay = (props) => {
    const [ active, setActive ] = useState(false)
    const [ animate, setAnimate ] = useState(false)

    const { visible = false, children, handleClick } = props

    useEffect(() => {

        if (visible) {
            setActive(true)
            setAnimate(true)
        }

        if (!visible && active) {
            setAnimate(false)
            setTimeout(() => {
                setActive(false)
            }, 200)
        } 

    }, [visible])

    if (!visible && !active) return null
    return (
        <StyledOverlay animate={animate} onClick={handleClick}>{children}</StyledOverlay>
    )
}

Overlay.propTypes = {
    children: PropTypes.any,
    handleClick: PropTypes.func,
    zIndex: PropTypes.string
}

export { Overlay }