import styled from 'styled-components'
import { Link } from 'gatsby'
import {
  spacing,
  colors,
  maxWidths,
  mq,
} from '../../styles/utilities/variables'
import StyledInteractiveHero from '../InteractiveHero/style'

const StyledBreadcrumbs = styled.nav`
  padding: ${spacing.medium} 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
  color: ${props =>
    props.colorTheme === 'light' ? colors.white : colors.black};
  a {
    color: ${props =>
      props.colorTheme === 'light' ? colors.white : colors.black};
  }

  @media ${mq.mobile} {
    color: ${props =>
      props.mobileColorTheme ? colors[props.mobileColorTheme] : colors.black};
    position: relative;
    padding-top: 0;
    a {
      color: ${props =>
        props.mobileColorTheme ? colors[props.mobileColorTheme] : colors.black};
    }
    & + ${StyledInteractiveHero} {
      margin-top: 0;
    }
  }
`

StyledBreadcrumbs.List = styled.ol`
  margin: 0;
  display: flex;
  padding: 0;
  flex-wrap: wrap;
  list-style: none;
  align-items: center;
  max-width: ${maxWidths.wideContainer};
  margin: 0 auto;
  width: 100%;
  padding: 0;
  position: relative;
  padding-left: ${spacing.large};
  padding-right: ${spacing.large};
  @media ${mq.tablet} {
    padding-left: ${spacing.small};
    padding-right: ${spacing.small};
  }
`

StyledBreadcrumbs.ListSeperator = styled.li`
  margin: 0 ${spacing.xxSmall};
  font-size: 1.3rem;

  & div {
    display: flex;
  }

  @media ${mq.mobile} {
    &:not(:nth-child(2)):nth-last-child(2) {
      display: none;
    }
  }
`

StyledBreadcrumbs.ListItem = styled.li`
  font-size: 13px;
  @media ${mq.mobile} {
    font-size: 1.3rem;
    &:not(:nth-child(3)):last-child {
      display: none;
    }
  }
`

StyledBreadcrumbs.Link = styled(Link)`
  font-weight: bold;
  text-decoration: underline;
`

StyledBreadcrumbs.Item = styled.span``

export default StyledBreadcrumbs
