export const SPACING = {
  XXX_LARGE: 'xxxLarge',
  XX_LARGE: 'xxLarge',
  X_LARGE: 'xLarge',
  LARGE: 'large',
  MEDIUM: 'medium',
  BASE: 'base',
  SMALL: 'small',
  X_SMALL: 'xSmall',
  XX_SMALL: 'xxSmall',
}

export const COLORS = {
  /*   YELLOW: 'yellow',
  ORANGE: 'orange',
  BLUE: 'blue',
  GREEN: 'green',
  BLACK: 'black',
  BROWN: 'brown',
  RED: 'red', */

  PRIMARY: 'primary',
  SECONDARY: 'secondary',

  WHITE: 'white',
  BLACK: 'black',
  DARK_GREY: 'darkGrey',

  GREY: 'grey',
  LIGHT_GREY: 'lightGrey',
  LIGHTEST_GREY: 'lightestGrey',

  BRAND_GREEN: 'brandGreen',
  BRAND_ORANGE: 'brandOrange',
  BRAND_BEIGE: 'brandBeige',

  ALERT_RED: 'alertRed',
  ALERT_YELLOW: 'alertYellow',
  ALERT_GREEN: 'alertGreen',

  NB_ONE: 'nbOne',
  NB_TWO: 'nbTwo',
  NB_THREE: 'nbThree',
  NB_FOUR: 'nbFour',
  NB_FIVE: 'nbFive',
  NB_SIX: 'nbSix',

  HS_ONE: 'hsOne',
  HS_TWO: 'hsTwo',
  HS_THREE: 'hsThree',
  HS_FOUR: 'hsFour',
  HS_FIVE: 'hsFive',
  HS_SIX: 'hsSix',

  SD_ONE: 'sdOne',

  GRAPHITE: 'graphite',
  GREEN: 'green',

  BORDER_BOLOR: 'borderColor',
  PLACEHOLDER: 'placeholder',
  TRANSPARENT: 'transparent',
}

export const FONT_SIZE = {
  XXX_LARGE: 'xxxLarge',
  XX_LARGE: 'xxLarge',
  X_LARGE: 'xLarge',
  LARGE: 'large',
  MEDIUM: 'medium',
  BASE: 'base',
  MID_BASE: 'midBase',
  SMALL: 'small',
  X_SMALL: 'xSmall',
  XX_SMALL: 'xxSmall',
  XXX_SMALL: 'xxSmall',
  PREAMBLE: 'preamble',
}

export const THEMES = {
  MASTER: 'master',
  NEWBODY: 'newbody',
  SPICY_DREAM: 'spicyDream',
}

export const STATUS_TYPES = {
  PLACE_ORDER: 'PLACE_ORDER',
  FETCH_PRODUCTS: 'FETCH_PRODUCTS',
  FETCH_SHOPS: 'FETCH_SHOPS',
}

export const PREVIEW_COOKIE = 'preview'

export const STOCK_STATUS = {
  IN_STOCK: 'IN_STOCK',
  OUT_OF_STOCK: 'OUT_OF_STOCK',
}
