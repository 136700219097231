import React from 'react'
import PropTypes from 'prop-types'
import StyledAnimate from './style'

const Animate = ({ to, from, animate, size = 20 }) => {
  return (
    <StyledAnimate animate={animate} size={size}>
      <StyledAnimate.RotateFrom>{from}</StyledAnimate.RotateFrom>
      <StyledAnimate.RotateTo>{to}</StyledAnimate.RotateTo>
    </StyledAnimate>
  )
}

Animate.propTypes = {
  from: PropTypes.any.isRequired,
  to: PropTypes.any.isRequired,
  animate: PropTypes.bool.isRequired,
  size: PropTypes.number,
}

export { Animate }

const AnimateClassName = ({ active, children }) => {
  return (
    <StyledAnimate.ClassName active={active}>
      {children}
    </StyledAnimate.ClassName>
  )
}

AnimateClassName.propTypes = {
  active: PropTypes.bool.isRequired,
  children: PropTypes.any.isRequired,
}

export { AnimateClassName }
