import { useState, useEffect } from 'react'
import { getCurrentLang } from '../../helpers/lang'
import { NB_SHOP_URL } from '../../helpers/utilities'
const menuData = require('../../../public/cache/menu.json')
const notificationData = require('../../../public/cache/notifications.json')
const { getString } = require('../../helpers/lang')

export const useHeaderData = () => {
  const [shopMenuData, setShopMenu] = useState()
  const currentLang = getCurrentLang().toLowerCase()
  const webshopText = getString('HEADER_WEBSHOP_TEXT')
  const fetchUrl = NB_SHOP_URL ? `${NB_SHOP_URL}/cache/menu.json` : null

  const langMenus = [
    `primary-navigation`,
    `primary-navigation-${currentLang}`,
    `secondary-navigation`,
    `secondary-navigation-${currentLang}`,
    `topbar-navigation-primary`,
    `topbar-navigation-primary-${currentLang}`,
    `topbar-navigation-secondary`,
    `topbar-navigation-secondary-${currentLang}`,
  ]

  const allMenus = menuData
    .filter(menu => langMenus.includes(menu.slug))
    .map(menu => {
      menu.slug = menu.slug.replace(`-${currentLang}`, '')
      return menu
    })

  useEffect(() => {
    const dataFetch = async () => {
      if (!fetchUrl) return
      await fetch(fetchUrl)
        .then(response => {
          return response.json()
        })
        .then(data => {
          const shopArray = [
            {
              text: webshopText,
              children: [],
            },
          ]
          shopArray[0].children.push(...data)
          setShopMenu(shopArray)
        })
    }
    dataFetch()
  }, [])

  return {
    menu: allMenus,
    notificationMessage: notificationData.generalNotificationMessage,
    shopMenu: shopMenuData,
  }
}
