import { spacing, typography } from "../../styles/utilities/variables"
import styled, { css } from "styled-components"

const StyledNotification = styled.aside`
  background:#575757;

  color:white;
  transition: max-height 0.2s ease-in-out, opacity 0.2s ease-in-out;
  max-height:0;
  opacity: 0;
  overflow: hidden;
  ${props => props.visible && css`
    max-height:300px;
    opacity: 1;
  `}
`

StyledNotification.Inner = styled.div`
    display:flex;
    align-items:center;
    font-size:${typography.sizes.small};
    padding:${spacing.medium} 0;
    & > *:last-child {
        margin-left: auto;
        padding-left:20px;
        cursor: pointer;
    }
`

export default StyledNotification