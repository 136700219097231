import React from 'react'
import iPaymentLogos from './interface'
import StyledPaymentLogos from './style'
import { PATH_PREFIX } from '../../helpers/url'
import { getCurrentLang } from '../../helpers/lang'

const PaymentLogos = (props: iPaymentLogos) => {
  const { theme = 'light' } = props

  const currentLang = getCurrentLang()
  return (
    <StyledPaymentLogos>
      <>
        <img
          loading={'lazy'}
          src={PATH_PREFIX + `images/swedbank_pay_${theme}.png`}
          alt={'Swedbank Pay'}
        />
        {currentLang === 'sv_SE' ||
          ('sv' && (
            <>
              <img
                loading={'lazy'}
                src={PATH_PREFIX + `images/swish_${theme}.png`}
                alt={'Swish'}
              />
            </>
          ))}
        <img
          loading={'lazy'}
          src={PATH_PREFIX + `images/mastercard_${theme}.png`}
          alt={'Mastercard'}
        />
        <img
          loading={'lazy'}
          src={PATH_PREFIX + `images/visa_${theme}.png`}
          alt={'Visa'}
        />
        <img
          loading={'lazy'}
          src={PATH_PREFIX + `images/trustly_green.png`}
          alt={'Trustly'}
        />
        <img
          loading={'lazy'}
          src={PATH_PREFIX + `images/apple_pay_${theme}.svg`}
          alt={'Apple Pay'}
        />
      </>
    </StyledPaymentLogos>
  )
}

export { PaymentLogos }
