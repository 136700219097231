import React from 'react'
import StyledSideList from './style'
import PropTypes from 'prop-types'
import { RecursiveMenu, RecursiveMenuContainer } from '../RecursiveMenu'
import { SpecialItem } from '../SpecialItem'
import { Wrapper } from '../../../components/Wrapper'
import { getString } from '../../../helpers/lang'

const SideList = ({ listItems, shopMenu }) => {
  return (
    <StyledSideList>
      <Wrapper justify={'flex-end'} columnGap={'base'}>
        <SpecialItem
          href={getString('HEADER_TOURNAMENT_URL')}
          text={getString('HEADER_TOURNAMENT_TEXT')}
          iconType={'external-link'}
          iconSize={12}
        />
        <SpecialItem
          href={getString('HEADER_LOG_IN_URL')}
          text={getString('HEADER_LOG_IN_TEXT')}
          iconType={'external-link'}
          iconSize={12}
        />
      </Wrapper>

      <RecursiveMenuContainer>
        <RecursiveMenu items={listItems} shopMenu={shopMenu} />
      </RecursiveMenuContainer>
    </StyledSideList>
  )
}

SideList.propTypes = {
  listItems: PropTypes.array,
  handleLevel: PropTypes.array,
}

export { SideList }
