import React from 'react'
import PropTypes from 'prop-types'
import StyledMetaTitle from './style'

const MetaTitle = ({ children, color, center, bold = true }) => {
  return (
    <StyledMetaTitle color={color} center={center} bold={bold}>
      {children}
    </StyledMetaTitle>
  )
}

MetaTitle.propTypes = {
  color: PropTypes.string,
}
export { MetaTitle }
