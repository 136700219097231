import styled, { css } from 'styled-components'
import { spacing, mq } from '../../styles/utilities/variables'

const StyledRow = styled.div`
  display:flex;
  width: ${(props) => (props.auto) ? 'auto' : '100%'};
  height: ${(props) => (props.height) ? props.height : 'auto'};
  align-items: center;
  justify-content: ${(props) => (props.justify) ? props.justify : 'space-between'};
  align-items: ${(props) => (props.align) ? props.align : 'center'};

  ${props => props.spacing && css`
    & > * {
      margin-right: ${(props) => (props.spacing && spacing[props.spacing]) ? spacing[props.spacing] : spacing.medium};
      &:last-child {
        margin-right:0;
      }
    }
`}
 ${props => props.responsive && css`
    @media ${mq.mobile} {
      display: grid;
      grid-row-gap: ${spacing.medium};
    }
`}
`

export default StyledRow
