import styled, { css } from 'styled-components'
import {
  spacing,
  mq,
  maxWidths,
  colors,
} from '../../styles/utilities/variables'

const getMaxWidth = size => {
  const sizes = {
    narrowest: maxWidths.narrowestContainer,
    narrow: maxWidths.narrowContainer,
    normal: maxWidths.container,
    wide: maxWidths.wideContainer,
    fullScreen: '100%',
  }
  return sizes[size]
}

const StyledContainer = styled.div`
  max-width: ${props =>
    props.size ? getMaxWidth(props.size) : getMaxWidth('normal')};
  margin: 0 auto;
  width: 100%;
  padding: 0 ${spacing.large};
  position: relative;
  overflow-x: ${props => (props.overflow ? 'hidden' : '')};

  @media ${mq.tablet} {
    padding: 0 ${spacing.medium};
  }
  ${props =>
    props.noPadding &&
    css`
      padding: 0 !important;
    `}
  @media ${mq.mobile} {
    padding: 0 ${spacing.base};
  }

`

export default StyledContainer
