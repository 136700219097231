import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Header from '../Header'
import { ResetStyle } from '../../styles/base/reset'
import { GlobalStyle } from '../../styles/base/globalStyles'
import FooterNew from '../Footer'
import Main from '../Main'
import Tracking from '../Tracking'
import { DevelopmentBranch } from '../DevelopBranch'
import { initTracking } from '../../helpers/tracking'
import Footer from '../Footer'
import { SpecialAccessModal } from '../../components/SpecialAccessModal'
const Layout = ({ children }) => {
  useEffect(() => {
    initTracking()
  }, [])

  return (
    <>
      <Header />
      <ResetStyle />
      <GlobalStyle />
      <Main>{children}</Main>
      <Footer />
      {process.env.CURRENT_BRANCH && (
        <DevelopmentBranch
          branch={process.env.CURRENT_BRANCH}
          reloadUrl={'https://www.newbodyfamily.com/sv-se/shop'}
        />
      )}
      <Tracking />
      <SpecialAccessModal />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
