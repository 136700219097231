import React from 'react'
import StyledNotification from './style'
import { Container } from '../Container'
import { Icon } from '../Icon'
import { useState } from 'react'
import { getCookie, setCookie } from '../../helpers/storage'
import { useEffect } from 'react'
import { getIsClient } from '../../helpers/utilities'

const Notification = ({ children }) => {
  const COOKIE = 'closed_notification'
  const [visible, setVisible] = useState(false)

  const handleClick = () => {
    setVisible(false)
    setCookie(COOKIE, true, 1)
  }

  useEffect(() => {
    if (!visible && !getCookie(COOKIE)) setVisible(true)
  }, [])

  return (
    <StyledNotification visible={visible}>
      <Container>
        <StyledNotification.Inner>
          <div>{children}</div>
          <Icon
            handleClick={handleClick}
            color={'white'}
            icon={'times-circle'}
          />
        </StyledNotification.Inner>
      </Container>
    </StyledNotification>
  )
}

export { Notification }
