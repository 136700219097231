import styled, { css } from 'styled-components'
import { spacing, colors, mq } from '../../styles/utilities/variables'

const dotted = props => css`
  border-bottom: 6px dotted ${colors[props.dots]};
  background-color: transparent;
`

const lined = props => css`
  border-bottom: 1px solid ${colors.borderColor};
`

const StyledDivider = styled.div`
  height: ${props => (props.border ? '1px' : 0)};
  width: 100%;
  margin: 0;
  padding-top: ${props => props.size ? spacing[props.size] : spacing.medium};
  padding-bottom: ${props => props.size ? spacing[props.size] : spacing.medium};
  display: block;
  position: relative;
  
  @media ${mq.tablet} {
    ${props => !props.size && css`
      padding:${spacing.base} 0;
    `}
  }

  background:${props => props.debug ? 'red' : 'transparent'};
    
  &::after {
    content: '';
    position: absolute;
    display: inline-block;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    ${props => (props.dots ? dotted : '')};
    ${props => (props.line ? lined : '')};
  }
`

export default StyledDivider
