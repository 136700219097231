import React, { useState, useEffect, useMemo } from 'react'
import { useLocation } from '@reach/router'
import StyledHeader, {
  StyledLogotype,
  StyledPrimary,
  StyledSecondary,
  StyledCartHeader,
} from './style'
import { NavItem } from './NavItem'
import { Container } from '../../components/Container'
import StyledMq from '../../styles/utilities/mq'
import { Row } from '../../components/Row'
import Sidebar from '../Sidebar'

import { useHeaderData } from './hook'
import { Link } from '../../components/Link'
import { getUrl } from '../../helpers/url'
import { getSvgIcon } from '../../helpers/svgIcons'
import { getString, getShowLanguageSwitcher } from '../../helpers/lang'
import { LanguageSwitcher } from '../../components/LanguageSwitcher'
import { Notification } from '../../components/Notification'
import { AnimateClassName } from '../../components/Animate'
import { Icon } from '../../components/Icon'
import { COLORS } from '../../helpers/constants'
import HeaderDropdown from './Dropdown'
import { useResponsive } from '../../hooks/useResponsive'
import { Wrapper } from '../../components/Wrapper'
import { getCookie } from '../../helpers/storage'
import { BallBounce } from '../../components/BallBounce'
import {
  getIsClient,
  currentPathWithoutPrefix,
  NB_SHOP_URL,
} from '../../helpers/utilities'
import { findMenuItem, getMenuHandler } from '../../helpers/menu'
import { QuickMenu } from '../QuickMenu'

const Header = () => {
  const { menu, notificationMessage, shopMenu } = useHeaderData()

  const [level, setLevel] = useState(-1)
  const [levelItems, setLevelItems] = useState([])
  const [sidebarOpen, setSidebarOpen] = useState(false)

  const [currentPage, setCurrentPage] = useState(currentPathWithoutPrefix())
  const [currentPageChildren, setCurrentPageChildren] = useState(null)
  const [childActive, setChildActive] = useState(false)
  const [currentParent, setCurrentParent] = useState(false)
  const [openDropdown, setOpenDropDown] = useState(false)
  const [animate, setAnimate] = useState(false)
  const { breakpoints } = useResponsive()
  const isMobile = breakpoints.mobile
  const isTablet = breakpoints.smallTablet

  let primary = getMenuHandler(menu, 'primary-navigation')
  //let primary = getPrimaryHandler(menu, shopMenu)

  const secondary = getMenuHandler(menu, 'secondary-navigation') || []
  const shouldNotAlwaysBeShown = !getShowLanguageSwitcher()

  let unfilteredPrimary = primary

  //prevent Webshop to multiply on reload
  let isMerged = primary?.some(element => {
    return element.text === getString('HEADER_WEBSHOP_TEXT')
  })

  useMemo(() => {
    if (!isMerged) {
      if (shopMenu) {
        const reversedShopItems = [...shopMenu].reverse()

        reversedShopItems.map(shopItem => {
          primary.splice(1, 0, shopItem)
        })
      }
    }
  }, [isMerged, shopMenu])

  //useMenu()

  const dropdownHandler = (key, items) => {
    //check if items are the same, then close dropdown
    if (items[0]?.id === levelItems[0]?.id) {
      setOpenDropDown(!openDropdown)
      setLevelItems([])
    } else {
      //if items not the same, switch items in dropdown
      setLevelItems(items)
      setOpenDropDown(true)
      setLevel(key)
    }
  }

  useEffect(() => {
    if (!isMobile) {
      setSidebarOpen(false)
    }
  }, [isMobile])

  const cookieValue = getIsClient() ? getCookie('cartItems') : 0

  /**
   * Find current page's children for the bottom header
   * creds to Simon for this function :D
   */

  useEffect(() => {
    const basePath = `/${currentPage.split('/').at(1)}/`
    const result = findMenuItem(primary, 'url', basePath)
    
    if (result?.childItems?.nodes.length > 0) {
      //results when clicked on parent
      setCurrentPageChildren(result.childItems.nodes)

      setCurrentParent(result)
    }
    if (result?.parent) {
      // results when clicked on child
      setCurrentParent(result.parent)

      setChildActive(true)

      setCurrentPageChildren(result.parent.childItems.nodes)
    }
    if (!currentPageChildren) {
      setCurrentPageChildren([])
    }
  }, [currentParent.url])
  
  const isEnrichmentPage = true

  return (
    <>
      <StyledHeader>
        <StyledHeader.Upper>
          <Container wide>
            <StyledHeader.Information>
              <StyledHeader.LeftSide>
                <StyledMq.Desktop smallTablet>
                  <StyledLogotype>
                    <Link to={getUrl('/', true)}>
                      {getSvgIcon('fullLogotypeNeg', 'white')}
                    </Link>
                  </StyledLogotype>
                </StyledMq.Desktop>

                {primary.map((item, index) => {
                  const { label, text, url } = item
                  let href
                  if (item.text) {
                    // check if item has text(should only exist on shop items)

                    // href = getString('HEADER_WEBSHOP_URL')
                    href = NB_SHOP_URL
                  } else {
                    href = getUrl(url)
                  }

                  return (
                    <StyledHeader.ListItem
                      key={index}
                      current={url && currentParent.url === url ? true : false}
                      currentParent={
                        currentParent.url === url ? childActive : false
                      }
                    >
                      {getSvgIcon('dots', 'white')}
                      {item.text && (
                        <StyledHeader.InformationItem.External
                          href={href}
                          current={false}
                          onClick={() => setAnimate(true)}
                        >
                          {label || text}
                        </StyledHeader.InformationItem.External>
                      )}
                      {!item.text && (
                        <StyledHeader.InformationItem
                          to={href}
                          current={currentParent.url === url ? true : false}
                          currentParent={
                            currentParent.url === url ? childActive : false
                          }
                        >
                          {label || text}
                        </StyledHeader.InformationItem>
                      )}
                    </StyledHeader.ListItem>
                  )
                })}
              </StyledHeader.LeftSide>
              <StyledHeader.RightSide>
                <StyledMq.Desktop mobile></StyledMq.Desktop>
                <StyledHeader.InformationItem.External
                  href={getString('HEADER_LOG_IN_URL')}
                  target={'_blank'}
                >
                  {getString('HEADER_LOG_IN_TEXT')}
                  <Icon icon={'external-link'} size={14} color={COLORS.WHITE} />
                </StyledHeader.InformationItem.External>
                <LanguageSwitcher />
              </StyledHeader.RightSide>
            </StyledHeader.Information>
          </Container>
        </StyledHeader.Upper>
        <StyledHeader.Lower>
          <Container wide>
            <StyledHeader.Navigation>
              <StyledPrimary>
                {currentPageChildren?.map((navItem, key) => {
                  const { label, childItems, url, cssClasses } = navItem

                  const hasChildren = childItems?.nodes.length > 0
                  const isOpen = level === key
                  return (
                    <>
                      <NavItem
                        key={key}
                        cssType={cssClasses[0] ?? 'icon'}
                        open={isOpen && openDropdown}
                        href={url ? getUrl(url) : null}
                        onClick={
                          hasChildren
                            ? () => dropdownHandler(key, childItems.nodes)
                            : null
                        }
                      >
                        <>
                          {label} {hasChildren && <Icon icon={'angle-down'} />}
                        </>
                      </NavItem>
                      {hasChildren && (
                        <HeaderDropdown
                          items={levelItems}
                          open={openDropdown}
                          currentPage={currentPage}
                        />
                      )}
                    </>
                  )
                })}
              </StyledPrimary>
              {secondary && (
                <StyledSecondary>
                  {secondary.map((navItem, key) => {
                    const { label, childItems, cssClasses, url } = navItem
                    return (
                      <NavItem
                        key={key}
                        cssType={cssClasses[0]}
                        href={url ? url : null}
                      >
                        {label}
                      </NavItem>
                    )
                  })}
                  <CartHeader totalItems={cookieValue} />
                </StyledSecondary>
              )}

              <StyledMq.SmallTablet>
                <Row justify={'space-between'}>
                  <StyledLogotype>
                    <Link to={getUrl('/', true)}>
                      {getSvgIcon('fullLogotype')}
                    </Link>
                  </StyledLogotype>
                  <Wrapper gap={'base'} align='center'>
                    <CartHeader totalItems={cookieValue} />
                    <NavItem onClick={() => setSidebarOpen(!sidebarOpen)}>
                      <AnimateClassName active={sidebarOpen}>
                        {getSvgIcon('hamburger')}
                      </AnimateClassName>
                    </NavItem>
                  </Wrapper>
                </Row>
              </StyledMq.SmallTablet>
            </StyledHeader.Navigation>
          </Container>
        </StyledHeader.Lower>
        {isEnrichmentPage && !sidebarOpen && isTablet ? <QuickMenu /> : null}
      </StyledHeader>
      <Sidebar
        mobileItems={[...primary, ...secondary]}
        //shopItems={shopMenu}
        items={levelItems}
        keyValue={level}
        sidebarOpen={sidebarOpen}
        handleOverlayClick={() => setSidebarOpen(!sidebarOpen)}
      />
      {notificationMessage && (
        <Notification>{notificationMessage}</Notification>
      )}
    </>
  )
}

const CartHeader = ({ totalItems }) => {
  if (totalItems > 0) {
    return (
      <StyledCartHeader>
        <Link to={getString('HEADER_CART_URL')}>
          <BallBounce number={totalItems} />
          {getSvgIcon('cartFilled')}
        </Link>
      </StyledCartHeader>
    )
  }
  return (
    <StyledHeader.InformationItem
      href={getString('HEADER_CART_URL')}
      target={'_blank'}
      color={COLORS.BLACK}
      visible
    >
      {getSvgIcon('cart', 'black')}
    </StyledHeader.InformationItem>
  )
}

export default Header
