import React from 'react'
import StyledSpecialItem from './style'
import PropTypes from 'prop-types'
import { getSvgIcon } from '../../../helpers/svgIcons'
import { Icon } from '../../../components/Icon'

const SpecialItem = props => {
  const {
    text,
    href,
    iconType,
    specialIcon,
    iconColor = 'white',
    iconSize = 20,
    active,
    target = '',
  } = props

  const IconComponent = specialIcon ? (
    getSvgIcon(iconType, active ? 'brandOrange' : iconColor, iconSize)
  ) : (
    <Icon
      icon={iconType}
      size={iconSize}
      color={active ? 'brandOrange' : iconColor}
    />
  )

  return (
    <StyledSpecialItem href={href} active={active} target={target}>
      {text}
      {IconComponent}
    </StyledSpecialItem>
  )
}

SpecialItem.propTypes = {
  text: PropTypes.string,
  href: PropTypes.string,
  iconType: PropTypes.string,
  iconColor: PropTypes.string,
  iconSize: PropTypes.number,
  active: PropTypes.bool,
  target: PropTypes.string,
  specialIcon: PropTypes.bool,
}

export { SpecialItem }
