import { useMemo } from 'react'
import { PATH_PREFIX } from '../../node/functions'

/**
 * Strip tags
 * @param {*} string
 */
export const stripTags = (string = '') => {
  return string.replace(/<[^>]*>?/gm, '')
}
/**
 * Search in array
 * @param {*} options
 * @param {*} key
 * @param {*} value
 */
export const useSearch = (options, key, value) => {
  return useMemo(() => {
    if (!value || value === '') return options
    const re = new RegExp(value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'i')
    return options.filter(t => re.test(t[key])).sort((a, b) => a.value - b.value)
  }, [options, key, value])
}


/**
 * Parse Content
 * @param {*} content 
 */
export const parseContent = (content) => {
  if (content && PATH_PREFIX !== '/') content = content.replace(/href=\"\//g, `href="${PATH_PREFIX}`)
  return content
}