import styled from 'styled-components'
import iPaymentLogos from './interface'
import { mq, spacing } from '../../styles/utilities/variables'

const StyledPaymentLogos = styled.div<iPaymentLogos>`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-self: center;
  gap: ${spacing.medium};
  grid-column: 7/12;
  @media (max-width: 1300px) {
    grid-column: 6/12;
  }
  @media ${mq.mobile} {
    flex-wrap: wrap;
  }

  img {
    height: 2rem;
    &:last-child {
      margin-right: 0;
    }
  }
`

export default StyledPaymentLogos
