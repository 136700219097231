import styled from 'styled-components'
import { colors } from '../../styles/utilities/variables'

const StyledBallBounce = styled.div`
  width: 19px;
  height: 19px;
  position: absolute;
  top: -8px;
  right: -8px;
  background: ${colors.lightGreen};
  color: ${colors.white};
  display: flex;
  font-weight: 500;
  font-size: 1rem;
  align-items: center;
  justify-content: center;
  border-radius: 999px;
  transform: ${props =>
    props.bounce ? 'scale(1.2) translateY(-8px)' : 'scale(1) translateY(0px)'};
  transition: transform 0.45s;
`

export default StyledBallBounce
