import React, { useState } from 'react'
import StyledDevelopmentBranch, { DevelopmentBodyStyle } from './style'
import { Button } from '../../components/Button'
import { Icon } from '../../components/Icon'
import { COLORS } from '../../helpers/constants'

export const DevelopmentBranch = ({ branch, reloadUrl }) => {
  const [reload, setReload] = useState(false)

  const doReload = () => {
    setReload(true)
    setTimeout(() => {
      setReload(false)
    }, 1000)
  }

  const handleClick = () => {
    doReload()
    window.location.href = reloadUrl
  }

  return (
    <StyledDevelopmentBranch>
      <DevelopmentBodyStyle />
      <StyledDevelopmentBranch.Title>
        You're currently looking at {branch} branch.
      </StyledDevelopmentBranch.Title>
      <StyledDevelopmentBranch.Action reload={reload}>
        <Button secondary handleClick={handleClick}>
          Production <Icon size={14} icon={'redo'} color={COLORS.PRIMARY} />
        </Button>
      </StyledDevelopmentBranch.Action>
    </StyledDevelopmentBranch>
  )
}
