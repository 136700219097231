import styled from 'styled-components'

const StyledVideo = styled.figure`
  will-change: opacity;
  transition: opacity ease-in-out 0.5s;
  opacity: ${props => (props.visible ? 1 : 0)};

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
export default StyledVideo
