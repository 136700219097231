import React from 'react'
import StyledNavItem from './style'
import { getUrl } from '../../../helpers/url'
import { Button } from '../../../components/Button'

const NavItem = props => {
  const { children, onClick, cssType, href, active } = props
  return onClick ? (
    <StyledNavItem.NavItemButton {...props}>
      {children}
    </StyledNavItem.NavItemButton>
  ) : href && cssType === 'icon' ? (
    <StyledNavItem.NavItemLink to={getUrl(href)} partiallyActive>
      {children}
    </StyledNavItem.NavItemLink>
  ) : href && cssType === 'button' ? (
    <Button to={getUrl(href, true)}>{children}</Button>
  ) : (
    <StyledNavItem active={active}>{children}</StyledNavItem>
  )
}

export { NavItem }
