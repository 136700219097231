import React from 'react'
import StyledFooter from './style'
import iFooter from './interface'

import { getString } from '../../helpers/lang'
import { ColumnItem } from './ColumnItem'
import { getSvgIcon } from '../../helpers/svgIcons'
import { Link } from '../../components/Link'
import { getUrl } from '../../helpers/url'
import { Icon } from '../../components/Icon'
import { PaymentLogos } from '../../components/PaymentLogos'
const footerMenu = require('../../../public/cache/footer.json')

const Footer = (props: iFooter) => {
  const { theme = 'greenTheme' } = props

  const {
    footerLinkGroups,
    footerContactTitle,
    footerContactEmail,
    footerContactMapUrl,
    footerOpeningHourGroups,
    footerIrregularOpeningHours,
    footerSocialLinks,
  } = footerMenu[0]

  const matchedIcons = [
    {
      name: 'Facebook',
      icon: 'facebook-square',
    },
    {
      name: 'Instagram',
      icon: 'instagram',
    },
    {
      name: 'LinkedIn',
      icon: 'linkedin-in',
    },
    {
      name: 'Twitter',
      icon: 'twitter',
    },
  ]
  return (
    <StyledFooter theme={theme}>
      <StyledFooter.CircleContainer>
        <StyledFooter.LeftCircle />
        <StyledFooter.RightCircle />
      </StyledFooter.CircleContainer>
      <StyledFooter.Upper>
        <ColumnItem span={2} hideInMobile padding>
          {getSvgIcon('iconLogotype', 'brightYellow', 63)}
        </ColumnItem>

        {footerLinkGroups &&
          footerLinkGroups.length > 0 &&
          footerLinkGroups.map((link, key) => {
            const { links, title } = link

            return (
              <ColumnItem
                key={key}
                expander
                span={2}
                list
                items={links}
                heading={title}
                mobileBorder
              />
            )
          })}

        <ColumnItem
          key={4}
          expander
          span={2}
          list
          items={[
            ...footerOpeningHourGroups.map(openingHour => {
              const text = openingHour.openingHours
                .map(o => {
                  return `${o.days} ${o.hours}`
                })
                .join('')
              return {
                fieldGroup: true,
                title: openingHour.title,
                text: text,
                link: {
                  url: `tel:${openingHour.contactPhone}`,
                  title: openingHour.contactPhone,
                },
              }
            }),
            {
              link: {
                url: footerIrregularOpeningHours?.link?.url,
                title: footerIrregularOpeningHours?.link?.title,
              },
            },
            {
              link: {
                url: `mailto:${footerContactEmail}`,
                title: footerContactEmail,
              },
            },
            {
              link: {
                url: `${footerContactMapUrl}`,
                title: 'Google maps',
                underline: true,
              },
            },
          ]}
          heading={footerContactTitle}
          mobileBorder
        />
      </StyledFooter.Upper>
      <StyledFooter.Bottom>
        <ColumnItem span={2} padding>
          <a
            style={{ display: 'inline-flex' }}
            href={getString('FOOTER_UC_LINK')}
            title={getString('FOOTER_UC_TEXT')}
            target='_blank'
          >
            <img loading={'lazy'} src={getString('FOOTER_UC_IMAGE')} alt='' />
          </a>
        </ColumnItem>
        <PaymentLogos theme='light' />
        <StyledFooter.Social>
          {footerSocialLinks.map((node, key) => {
            const { title, url } = node.link

            const matched = matchedIcons.find(item => item.name === title)

            return (
              <Link to={getUrl(url)} key={key} target={'_blank'}>
                <Icon
                  social
                  size={24}
                  color={'white'}
                  icon={matched ? matched.icon : ''}
                />
              </Link>
            )
          })}
        </StyledFooter.Social>
      </StyledFooter.Bottom>
    </StyledFooter>
  )
}

export default Footer
