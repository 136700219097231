import styled from 'styled-components'
import { cssEasings } from '../../styles/utilities/variables'

const StyledOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  transition: opacity 0.2s ${cssEasings.easeInOutQuart};
  z-index: 10;
  opacity: ${props => props.animate ? 1 : 0};
`

export default StyledOverlay
