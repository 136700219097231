import styled, { css } from 'styled-components'
import {
  spacing,
  mq,
  colors,
  typography,
} from '../../../styles/utilities/variables'
import StyledHeading from '../../../components/Heading/style'
import StyledIcon from '../../../components/Icon/style'
import StyledForm from '../../../components/Form/style'
import StyledContentExpander from '../../../components/ContentExpander/style'

const StyledColumnItem = styled.div`
  grid-column-start: span ${props => props.span};
  padding: ${props =>
    props.padding ? `${spacing.base} ${spacing.medium}` : `${spacing.base} 0`};
  justify-self: ${props => (props.align ? props.align : 'start')};
  width: 100%;


  & ${StyledIcon} {
    transition: transform 0.2s;
    transform: ${props => (props.isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  }

  @media ${mq.tabletMin} {
    & ${StyledContentExpander} {
      height: auto !important;
    }
    & ${StyledIcon} {
      display: none;
    }
  }
  @media (max-width: 1300px) {
    ${props =>
      props.hideInMobile &&
      css`
        grid-column-start: span 1;
        visibility: hidden;
      `}
    }
  
  @media ${mq.tablet} {
    width: 100%;
    grid-row-end: ${props => (props.reverse ? 1 : '')};
    grid-column-start: span 1;
    border-bottom: 1px solid
    ${props =>
      props.mobileBorder ? `rgb(255, 253, 250, 0.2)` : colors.transparent};
    padding: ${spacing.base} ${spacing.xSmall};
    ${props =>
      props.hideInMobile &&
      css`
        display: none;
      `}
    /* & ${StyledContentExpander} {
      height: 0 !important;
    } */
    /* & ${StyledIcon} {
      display: block;
    } */
  }
`

StyledColumnItem.Content = styled.div`
  display: flex;
  font-size: ${typography.sizes.small};
  line-height: ${typography.lineHeights.large};
  

  > ${StyledForm.Input} {
    max-width: 66%;
  }

  @media ${mq.tablet} {
    //padding-top: ${spacing.small};

    img {
      max-width: 20rem;
    }
    /* 
    > ${StyledForm.Input} {
      max-width: 100%;
      margin: 0;
      margin-bottom: ${spacing.xLarge};
      text-align: center;
    }
    */

  }
`

StyledColumnItem.Heading = styled(StyledHeading.h6)`
  margin-bottom: 0;
  font-size: ${typography.sizes.base};
  font-weight: 600;
  color: ${colors.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  pointer-events: none;

  @media ${mq.tablet} {
    font-size: ${typography.sizes.midBase};
    pointer-events: all;
    &:hover {
      cursor: pointer;
    }
  }
`

StyledColumnItem.List = styled.ul`
  list-style-type: none;
  padding-top: ${spacing.midBase};
  line-height: ${typography.lineHeights.large};
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  word-wrap: break-word;

  @media ${mq.mobile} {
    padding-top: ${spacing.small};
  }
`

StyledColumnItem.ListItem = styled.li`
  font-weight: 500;
  margin-bottom: ${spacing.small};
  font-size: ${typography.sizes.small};
  white-space: pre;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  word-wrap: break-word;
  white-space: normal;
  width: 100%;
  > span,
  p {
    color: ${colors.white};
    margin: 0;
  }
  > a {
    color: ${colors.white};
    position: relative;
    width: 100%;

    text-decoration: ${props => (props.underline ? 'underline' : '')};

    &:hover {
      text-decoration: underline;
    }
  }

  &:hover {
    a:after {
      background: ${colors.white};
    }
  }
`

export default StyledColumnItem
