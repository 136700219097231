import styled from 'styled-components'
import { colors } from "../../styles/utilities/variables";

const StyledIcon = styled.div`
  width: fit-content;
  i {
    font-size: ${({ size }) => (size ? (size + 'px') : '24px')};
    color: ${props => props.color ? colors[props.color] : colors.black }
  }

`

export default StyledIcon
