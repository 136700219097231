import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import StyledContentExpander from './style'

const ContentExpander = props => {
  const { children, open, duration = 0.4, border } = props
  const [overflowHidden, setOverflowHidden] = useState(true)

  useEffect(() => {
    if (open) setOverflowHidden(false)
  }, [])

  return (
    <StyledContentExpander
      animate={{ height: open ? 'auto' : 0 }}
      initial={false}
      transition={{ duration: duration }}
      open={open}
      border={border}
      onAnimationStart={() => {
        if (!open) setOverflowHidden(true)
      }}
      onAnimationComplete={() => {
        setOverflowHidden(!open)
      }}
      overflowHidden={overflowHidden}
    >
      {children}
    </StyledContentExpander>
  )
}

ContentExpander.propTypes = {
  children: PropTypes.any,
  open: PropTypes.bool,
  duration: PropTypes.number,
}

export { ContentExpander }
