import React from 'react'
import { Overlay } from '../../components/Overlay'
import StyledSidebar from './style'
import { SideList } from './SideList'
import StyledMq from '../../styles/utilities/mq'
import { LanguageSwitcherMobile } from '../../components/LanguageSwitcher'
import { Divider } from '../../components/Divider'
const Sidebar = props => {
  const { sidebarOpen, mobileItems, handleOverlayClick, items } = props

  return (
    <StyledSidebar>
      {sidebarOpen && (
        <Overlay visible={sidebarOpen} handleClick={handleOverlayClick} />
      )}
      <StyledSidebar.Slide level={1} open={sidebarOpen}>
        {/* <StyledMq.Desktop smallTablet>
          {items && items.length > 0 && <SideList listItems={items} />}
        </StyledMq.Desktop> */}
        <StyledMq.SmallTablet>
          {mobileItems && mobileItems.length > 0 && (
            <SideList listItems={mobileItems} />
          )}
          <Divider />
          <LanguageSwitcherMobile />
        </StyledMq.SmallTablet>
      </StyledSidebar.Slide>
    </StyledSidebar>
  )
}

export default Sidebar
