import styled, { css } from 'styled-components'
import {
  typography,
  spacing,
  cssEasings,
} from '../../styles/utilities/variables'

const StyledImage = styled.figure`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  align-items: ${props =>
    (props.align === 'right' && 'flex-end') ||
    (props.align === 'center' && 'center') ||
    'flex-start'};

  width: 100%;

  ${props =>
    !props.isLoaded &&
    !props.critical &&
    css`
      padding-bottom: 56.25%;
    `}

  ${props =>
    props.objectFit &&
    css`
      height: 100%;

      img,
      picture {
        height: 100%;
        object-fit: cover;
      }
    `}

  img,
  picture {
    width: ${props => (props.width ? props.width : '100%')};
    max-height: 100%;

    img {
      width: 100%;
    }
  }

  [data-placeholder] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    opacity: ${props => (props.isLoaded ? 0 : 1)};
    transition: opacity 0.3 ${cssEasings.easeInQuad};
    transition-delay: ${props => (props.isLoaded ? 0.3 : 0.2)};
    filter: blur(2rem);
  }
`

StyledImage.Caption = styled.figcaption`
  font-size: ${typography.sizes.small};
  text-align: center;
  margin-top: ${spacing.small};
  width: ${props => props.width};
  ${props =>
    !props.isLoaded &&
    css`
      display: none;
    `}
`

StyledImage.Img = styled.img`
  opacity: ${props => (props.src ? 1 : 0)};
  transition: opacity 500ms ease;
`

export default StyledImage
