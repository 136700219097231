import React from 'react'
import PropTypes from 'prop-types'
import StyledContainer from './style'

const Container = props => {
  const {
    children,
    wide,
    narrow,
    narrowest,
    fullScreen,
    noPadding,
    overflow,
  } = props

  const size =
    (fullScreen && 'fullScreen') ||
    (wide && 'wide') ||
    (narrow && 'narrow') ||
    (narrowest && 'narrowest') ||
    'normal'

  return (
    <StyledContainer size={size} noPadding={noPadding} overflow={overflow}>
      {children}
    </StyledContainer>
  )
}

Container.propTypes = {
  children: PropTypes.any,
  wide: PropTypes.bool,
  narrow: PropTypes.bool,
}

export { Container }
