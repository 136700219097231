import React from 'react'
import PropTypes from 'prop-types'
import StyledPageContent from './style'

const PageContent = ({ children, top = true, template = null }) => {
  return (
    <StyledPageContent top={top} template={template}>
      {children}
    </StyledPageContent>
  )
}

PageContent.propTypes = {
  children: PropTypes.any,
  top: PropTypes.bool,
  template: PropTypes.string,
}

export { PageContent }
