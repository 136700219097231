import React, { useContext } from 'react'

import { Link } from '../../../components/Link'
import { getUrl } from '../../../helpers/url'
import {
  StyledHeaderDropdown,
  StyledHeaderDropdownListItem,
  StyledHeaderDropdownLink,
} from './style'
import { findMenuItem } from '../../../helpers/menu'

const HeaderDropdown = ({ open, items, currentPage }) => {
  const result = findMenuItem(items, 'url', currentPage)

  return (
    <StyledHeaderDropdown isOpen={open}>
      <ul>
        {items.map((item, key) => {
          return (
            <StyledHeaderDropdownListItem
              key={key}
              active={result?.id === item.id ? true : false}
            >
              <StyledHeaderDropdownLink to={getUrl(item.url)}>
                {item.label}
              </StyledHeaderDropdownLink>
              {/* <Link href={getUrl(item.url)}>{item.label}</Link> */}
            </StyledHeaderDropdownListItem>
          )
        })}
      </ul>
    </StyledHeaderDropdown>
  )
}

export default HeaderDropdown
