import React from 'react'
import PropTypes from 'prop-types'
import StyledForm from './style'
import { useState } from 'react'
import DatePicker from 'react-datepicker'
import { Controller } from 'react-hook-form'
import 'react-datepicker/dist/react-datepicker.css'
import StyledText from '../Text/style'
import { Wrapper } from '../Wrapper'
import { getSvgIcon } from '../../helpers/svgIcons'

const Form = props => {
  return <StyledForm {...props}>{props.children}</StyledForm>
}

Form.Group = props => {
  return <StyledForm.Group {...props}>{props.children}</StyledForm.Group>
}

Form.Error = props => {
  return (
    <StyledForm.Error>
      {props.icon && getSvgIcon('MaterialIconWarning')}
      <span>{props.children}</span>
    </StyledForm.Error>
  )
}

Form.Label = props => {
  return <StyledForm.Label {...props}>{props.children}</StyledForm.Label>
}
Form.Description = props => {
  return (
    <StyledForm.Description>
      <StyledText size='xSmall'>{props.children}</StyledText>
    </StyledForm.Description>
  )
}

/**
 * Flying label input
 * @param {*} props
 */
const Input = props => {
  const { forceTop, label, placeholder, onBlur, onFocus, onChange } = props
  const newProps = { ...props }
  delete newProps.placeholder
  const defaultValue =
    props.defaultValue && !props.value ? props.defaultValue : props.value
  const [top, setTop] = useState(!!props.value)
  const [value, setValue] = useState(defaultValue || '')

  const handleChange = e => {
    const val = e.target.value
    setValue(val)
    if (onChange) onChange(e)
  }

  const handleBlur = () => {
    if (top && !value.length) setTop(false)
    if (onBlur) onBlur()
  }

  const handleFocus = () => {
    if (!top && !value.length) setTop(true)
    if (onFocus) onFocus()
  }

  return (
    <>
      <StyledForm.InputWrapper {...newProps}>
        <StyledForm.FlyingLabel top={top || forceTop}>
          {label}
        </StyledForm.FlyingLabel>
        <StyledForm.Input
          ref={props.innerRef}
          {...newProps}
          placeholder={(top || forceTop) && !value ? placeholder : null}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
        />
      </StyledForm.InputWrapper>
    </>
  )
}

Form.Input = props => {
  const { label } = props
  if (!label) return <StyledForm.Input ref={props.innerRef} {...props} />
  return <Input {...props} />
}

Form.TextArea = props => {
  return (
    <StyledForm.TextArea ref={props.innerRef} {...props}>
      {props.children}
    </StyledForm.TextArea>
  )
}

Form.Radio = props => {
  return <StyledForm.Radio ref={props.innerRef} {...props} />
}
Form.RadioWithContainer = props => {
  return <StyledForm.RadioWithContainer ref={props.innerRef} {...props} />
}

Form.Heading = ({ children }) => {
  return <StyledForm.Heading>{children}</StyledForm.Heading>
}

Form.File = props => {
  return (
    <StyledForm.File
      type={'file'}
      accept='image/png, image/jpeg'
      ref={props.innerRef}
      {...props}
    />
  )
}

Form.Section = ({ children, top }) => {
  return <StyledForm.Section top={top}>{children}</StyledForm.Section>
}

Form.Select = props => {
  const { options, top, forceTop, label } = props
  return (
    <StyledForm.InputWrapper>
      {label && (
        <StyledForm.FlyingLabel top={top || forceTop}>
          {label}
        </StyledForm.FlyingLabel>
      )}
      <StyledForm.Select ref={props.innerRef} {...props}>
        {options.map((option, key) => {
          return (
            <option key={key} value={option.value} disabled={option.disabled}>
              {option.name}
            </option>
          )
        })}
      </StyledForm.Select>
    </StyledForm.InputWrapper>
  )
}

Form.Checkbox = props => {
  return (
    <label htmlFor={props.id}>
      <StyledForm.Checkbox>
        <StyledForm.InputWrapper>
          <input
            id={props.id}
            ref={props.innerRef}
            type='checkbox'
            {...props}
          />
          <span className={'checkmark'}></span>
        </StyledForm.InputWrapper>
        <div>
          {props.label && (
            <span
              className={'label'}
              dangerouslySetInnerHTML={{ __html: props.label }}
            ></span>
          )}
          {props.subLabel && (
            <span className={'subLabel'}>{props.subLabel}</span>
          )}
        </div>
      </StyledForm.Checkbox>
    </label>
  )
}

Form.Concent = props => {
  return (
    <label htmlFor={props.id}>
      <StyledForm.Checkbox>
        <StyledForm.InputWrapper>
          <input
            id={props.id}
            ref={props.innerRef}
            type='checkbox'
            {...props}
          />
          <span className={'checkmark'}></span>
        </StyledForm.InputWrapper>
        {props.label && (
          <span dangerouslySetInnerHTML={{ __html: props.label }} />
        )}
      </StyledForm.Checkbox>
    </label>
  )
}

Form.Radio = props => {
  return (
    <StyledForm.Radio htmlFor={props.id}>
      <StyledForm.InputWrapper>
        <input id={props.id} ref={props.innerRef} type='radio' {...props} />
        <span className={'checkmark'}></span>
      </StyledForm.InputWrapper>
      {props.label && <span className={'label'}>{props.label}</span>}
    </StyledForm.Radio>
  )
}

Form.Date = props => {
  const { placeholder, name, register, isRequired, control } = props
  return (
    <StyledForm.DateWrapper>
      <Controller
        control={control}
        name={name}
        valueName={'selected'} // DateSelect value's name is selected
        defaultValue={''}
        render={({ onChange, onBlur, value }) => (
          <DatePicker
            onChange={onChange}
            // onBlur={onBlur}
            selected={value}
            customInput={<StyledForm.Input type={'text'} />}
            placeholderText={placeholder}
            dateFormat={'yyyy-MM-dd'}
          />
        )}
      />
    </StyledForm.DateWrapper>
  )
}

Form.RadioWithContainer = props => {
  return (
    <StyledForm.RadioWithContainer>
      <StyledForm.InputWrapper>
        <input id={props.id} ref={props.innerRef} type='radio' {...props} />
        <span className={'checkmark'}></span>
      </StyledForm.InputWrapper>
      <Wrapper direction={'column'}>
        {props.label && <span className={'label'}>{props.label}</span>}
        {props.description && (
          <span className={'subLabel'}>{props.description}</span>
        )}
      </Wrapper>
    </StyledForm.RadioWithContainer>
  )
}

Form.propTypes = {
  children: PropTypes.any,
  Label: {
    children: PropTypes.any,
  },
  Group: {
    children: PropTypes.any,
  },
  Error: {
    icon: PropTypes.bool,
  },
  Input: {
    name: PropTypes.string,
    valid: PropTypes.bool,
    inValid: PropTypes.bool,
    innerRef: PropTypes.any,
  },
  TextArea: {
    name: PropTypes.string,
    innerRef: PropTypes.any,
    children: PropTypes.any,
  },
  Select: {
    options: PropTypes.array,
    innerRef: PropTypes.any,
  },
  Checkbox: {
    innerRef: PropTypes.any,
    name: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
  },
  Radio: {
    innerRef: PropTypes.any,
    name: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
  },
  RadioWithContainer: {
    innerRef: PropTypes.any,
    name: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    description: PropTypes.string,
  },
}

export { Form }
