import styled from 'styled-components'

const StyledAnimate = styled.div`
  position: relative;
  height: ${props => (props.size ? props.size : '20')}px;
  width: ${props => (props.size ? props.size : '20')}px;

  > div {
    transform: ${props => (props.animate ? 'rotate(360deg)' : 'rotate(0)')};
    transition: all 0.3s;

    &:first-child {
      opacity: ${props => (props.animate ? '0' : '1')};
    }
    &:last-child {
      opacity: ${props => (props.animate ? '1' : '0')};
    }
  }
`

StyledAnimate.RotateFrom = styled.div`
  position: absolute;
  height: 100%;
`

StyledAnimate.RotateTo = styled.div`
  position: absolute;
  height: 100%;
`

StyledAnimate.ClassName = styled.div`
  height: 20px;
  .hamburger_upper {
    transform: translateY(${props => (props.active ? '7px' : '0')});
    opacity: ${props => (props.active ? 0 : 1)};
    transition: opacity 0.35s, transform 0.35s;
  }

  .hamburger_lower {
    transform: translateY(${props => (props.active ? '-7px' : '0')});
    opacity: ${props => (props.active ? 0 : 1)};
    transition: opacity 0.35s, transform 0.35s;
  }
`

export default StyledAnimate
