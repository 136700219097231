import React from "react"
import PropTypes from "prop-types"
import StyledIcon from "./style"

const Icon = ({ icon, size = 20, handleClick, color, social }) => {
  const prefix = social ? 'fab' : 'fal' 
  return (
    <StyledIcon onClick={handleClick} size={size} color={color}>
      <i className={`${prefix} fa-${icon}`}></i>
    </StyledIcon>
  )
}

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  size: PropTypes.number,
  handleClick: PropTypes.func,
  color: PropTypes.string,
  social: PropTypes.bool,
}

export { Icon }
