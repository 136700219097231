import { useEffect } from 'react'
import { doTrackChat, doTrackPageView } from '../../helpers/tracking'

const Tracking = () => {

  /**
   * Tracking
   */
  useEffect(() => {
    doTrackPageView()
    doTrackChat()
  }, [])

  return null
}

export default Tracking
