import styled from 'styled-components'
import { motion } from 'framer-motion'
import { spacing } from '../../styles/utilities/variables'

const StyledContentExpander = styled(motion.div)`
  overflow: ${props => (props.overflowHidden ? 'hidden' : 'visible')};

  //border for new navigation
  border-bottom: ${props =>
    props.border ? `1px solid rgba(255, 255, 255, 0.1)` : ''};
  padding-bottom: ${props => (props.border ? spacing.base : '')};
  transition: border-color 0.2s ease;
`

export default StyledContentExpander
