import React from 'react'
import PropTypes from 'prop-types'
import StyledPreamble from './style'

const Preamble = ({
  children,
  bold,
  center,
  width,
  color,
  size,
  lineHeight,
}) => {
  return (
    <StyledPreamble
      bold={bold}
      center={center}
      width={width}
      color={color}
      size={size}
      lineHeight={lineHeight}
    >
      {children}
    </StyledPreamble>
  )
}

Preamble.propTypes = {
  bold: PropTypes.bool,
  children: PropTypes.any,
  center: PropTypes.bool,
  width: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  lineHeight: PropTypes.string,
}
export { Preamble }
