import styled, { css } from 'styled-components'
import { Link as GatsbyLink } from 'gatsby'
import {
  typography,
  spacing,
  colors,
} from '../../../styles/utilities/variables'
import StyledIcon from '../../../components/Icon/style'

const SpecialItem = styled.a`
  /* Alignment */
  display: flex;
  align-items: center;

  /* Font styles */
  color: ${colors.white};
  font-family: ${typography.secondaryFont};
  font-size: ${typography.sizes.small};
  font-weight: 400;
  letter-spacing: 0.5px;

  /* Ground styles */
  padding: ${spacing.base} 0;
  margin: 0;
  border: none;
  outline: none;
  background: transparent;

  &:not(:last-child) {
  //  margin-bottom: ${spacing.midBase};
  }

  &:focus,
  &:active {
    outline: none;
  }

  svg,
  ${StyledIcon} {
    margin-left: ${spacing.xxSmall};
  }

  &[aria-current] {
    color: ${colors.brandOrange};
  }

  ${props =>
    props.active &&
    css`
      color: ${colors.brandOrange};
    `}
`

export default SpecialItem
