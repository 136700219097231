import styled, { createGlobalStyle } from 'styled-components'
import { Link } from 'gatsby'
import { colors, mq } from '../../styles/utilities/variables'
import StyledHeader from '../Header/style'

export const StyledQuickMenu = styled.ul`
  @media ${mq.smallTablet} {
    background-color: #f0eee7; // TODO add as variable
    position: fixed;
    top: 11rem; // TODO add as variable?
    width: 100%;
    display: flex;
    justify-content: space-between;
    list-style: none;

    li {
      width: 100%;
    }

    a {
      text-decoration: none;
      color: inherit;
    }

    @media ${mq.mobile} {
      top: 6rem;
    }
  }
`

export const GlobalHeaderStyle = createGlobalStyle`
  ${StyledHeader} {
    margin-bottom: 6rem;
  }
`

export const StyledQuickMenuItem = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 0.6rem;
  height: 6rem;
  font-weight: 500;
  font-size: 1.2rem;

  svg {
    width: 2.4rem;
    overflow: visible !important;
  }

  &.active {
    background-color: ${colors.darkGreen};
    color: ${colors.white_02};

    svg {
      path,
      circle {
        stroke: ${colors.white_02};
      }
    }
  }
`
