import React, { useRef, useState } from 'react'
import { useClickOutside } from '../../hooks/useClickOutside'
import { Icon } from '../Icon'
import { ContentExpander } from '../ContentExpander'
import StyledLanguageSwitcher from './style'
import { getCurrentLang, getShowLanguageSwitcher, languageSwitcher } from '../../helpers/lang'
import { COLORS } from '../../helpers/constants'
import { getUrl } from '../../helpers/url'

const LanguageSwitcher = props => {
  const { links } = languageSwitcher
  const [open, setOpen] = useState(false)
  const currentLang = getCurrentLang()

  const switcherRef = useRef(null)

  useClickOutside(switcherRef, () => {
    if (open) setOpen(false)
  })

  if (!getShowLanguageSwitcher()) return null

  return (
    <StyledLanguageSwitcher open={open} onClick={() => setOpen(!open)}>
      <StyledLanguageSwitcher.Globe open={open}>
        <Icon
          className='globe'
          size={18}
          icon='globe'
          color={open ? COLORS.RED : COLORS.WHITE}
        />
        <span>{currentLang.toUpperCase()}</span>
        <Icon
          size={10}
          icon='chevron-down'
          color={open ? COLORS.RED : COLORS.WHITE}
        />
      </StyledLanguageSwitcher.Globe>
      <StyledLanguageSwitcher.Container ref={switcherRef} open={open}>
        <StyledLanguageSwitcher.List>
          {links &&
            links.length > 0 &&
            links.map((item, index) => {
              const { url, langCode } = item.link
              return (
                <StyledLanguageSwitcher.ListItem key={index}>
                  {langCode === currentLang && <Icon icon={'check'} />}
                  <a href={getUrl(url)}>{langCode.toUpperCase()}</a>
                </StyledLanguageSwitcher.ListItem>
              )
            })}
        </StyledLanguageSwitcher.List>
      </StyledLanguageSwitcher.Container>
    </StyledLanguageSwitcher>
  )
}

const LanguageSwitcherMobile = (props) => {
  const { label, links } = languageSwitcher
  const [open, setOpen] = useState(false)
  const currentLang = getCurrentLang()
  if (!getShowLanguageSwitcher()) return null
  return (
    <StyledLanguageSwitcher.Mobile>
      <StyledLanguageSwitcher.MobileGlobe
        open={open}
        onClick={() => setOpen(!open)}
      >
        <Icon size={20} icon='globe' />
        {label}
        <Icon size={20} icon={'chevron-down'} />
      </StyledLanguageSwitcher.MobileGlobe>
      <ContentExpander open={open}>
        <StyledLanguageSwitcher.MobileList>
          {links &&
            links.length > 0 &&
            links.map((item, index) => {
              const { url, langCode } = item.link
              return (
                <StyledLanguageSwitcher.MobileListItem key={index}>
                  <a href={url}>
                    {langCode === currentLang && <Icon icon={'check'} />}
                    {langCode.toUpperCase()}
                  </a>
                </StyledLanguageSwitcher.MobileListItem>
              )
            })}
        </StyledLanguageSwitcher.MobileList>
      </ContentExpander>
    </StyledLanguageSwitcher.Mobile>
  )
}

export { LanguageSwitcher, LanguageSwitcherMobile }
