import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import {
  colors,
  mq,
  spacing,
  typography,
  radius,
  cssEasings,
} from '../../styles/utilities/variables'
import { Link as GatsbyLink } from 'gatsby'
import { darken } from 'polished'

const StyledAccordionListItem = styled.li`
  width: 100%;
  border-bottom: 1px solid ${colors.borderColor};
  overflow: hidden;
  padding: 0 ${spacing.base};
`

StyledAccordionListItem.Title = styled(motion.p)`
  margin: 0;
  line-height: 1.2;
  color: ${props => (props.color ? colors[props.color] : colors.darkGreen)};
  @media ${mq.mobile} {
    width: 100%;
  }
`

StyledAccordionListItem.TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${spacing.large} 0;
  cursor: pointer;
  @media ${mq.mobile} {
    padding: ${spacing.medium} 0;
    //gap: ${spacing.base};
  }
`

StyledAccordionListItem.Icon = styled.div`
  width: 32px;
  height: 32px;
  opacity: 0.3;
  margin-left: 20px;
  flex-shrink: 0;
  :before,
  :after {
    position: absolute;
    content: ' ';
    height: 33px;
    width: 2px;
    background-color: #333;
    transition: transform 0.2s;
  }

  :before {
    transform: ${props => (props.isOpen ? 'rotate(90deg)' : 'rotate(0deg)')};
  }

  :after {
    transform: rotate(90deg);
    visibility: ${props => (props.isOpen ? 'hidden' : 'visible')};
  }
`

StyledAccordionListItem.BodyContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0;
  width: 80%;
  line-height: 1.4;

  margin-bottom: ${spacing.xLarge};
  @media ${mq.tablet} {
    width: 100%;
  }
`

StyledAccordionListItem.Link = styled(GatsbyLink)`
  border-radius: ${radius.large};
  margin-top: ${spacing.medium};
  font-size: ${typography.sizes.xSmall};
  text-transform: uppercase;
  text-decoration: underline;
  color: ${props => (props.color ? colors[props.color] : colors.darkPink)};
  font-weight: 600;
  letter-spacing: 1.4px;
  margin-top: ${spacing.base};
  background-color: ${props =>
    props.color === 'black' ? colors.secondary : 'transparent'};
  padding: 0;

  ${props =>
    props.primary &&
    css`
      padding: ${props => (props.small ? spacing.small : spacing.base)}
        ${spacing.medium};
      background-color: ${colors.secondary};
      color: ${colors.primary};
      text-decoration: none;
      transition: background-color 0.3s ${cssEasings.easeInOutCubic},
        color 0.3s ${cssEasings.easeInOutCubic},
        transform 0.1s ${cssEasings.easeInOutCubic};
      &:hover {
        background-color: ${darken(0.1, colors.secondary)};
        border-color: ${darken(0.1, colors.secondary)};
        color: ${darken(0.1, colors.primary)};
      }
    `}
`

export default StyledAccordionListItem
