import styled, { css } from 'styled-components'
import iFooter from './interface'
import {
  colors,
  maxWidths,
  spacing,
  mq,
} from '../../styles/utilities/variables'
import StyledColumnItem from './ColumnItem/style'
import StyledPaymentLogos from '../../components/PaymentLogos/style'

const sharedGridStyles = () => css`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 1rem;
  padding: ${spacing.medium} 0;
  max-width: ${maxWidths.wideContainer};
  margin: 0 auto;
  position: relative;

  @media ${mq.tablet} {
    grid-template-columns: 1fr;
    grid-gap: 0;
    padding: ${spacing.base} ${spacing.small};
  }
`

const StyledFooter: any = styled.footer<iFooter>`
  // @TODO: Temp theme change
  /* background-color: ${props =>
    props.theme ? colors[props.theme].primary : colors.darkGreen};*/
  background-color: #1b1b1b;
  position: relative;
`

StyledFooter.CircleContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  overflow: hidden;
  max-width: 188rem;
  margin: 0 auto;
  @media (min-width: 1879px) {
    right: 0;
  }
`
StyledFooter.LeftCircle = styled.div`
  // @TODO: Temp theme change
  // background-color: ${colors.forestGreen};
  background-color: #3c3c3c;
  //grid-column: 7/13;
  border-radius: 50%;
  aspect-ratio: 1/1;
  overflow: hidden;
  width: 100%;
  max-width: 120rem;
  position: absolute;
  top: -20rem;
  left: -53rem;
  bottom: -10rem;
  z-index: 1;
  @media ${mq.desktopLarge} {
    left: -10rem;
  }
  @media (min-width: 1879px) {
    left: 0;
  }
  @media ${mq.tablet} {
    left: -28rem;
    top: -33rem;
    bottom: unset;
  }
  @media ${mq.mobile} {
    left: -16rem;
    top: -24rem;
  }
  @media (max-width: 460px) {
    width: 36rem;
    left: -12rem;
    top: -5rem;
  }
`

StyledFooter.RightCircle = styled.div`
  // @TODO: Temp theme change
  //  background-color: ${colors.lightGreen_02};
  background-color: #2b2b2b;
  //grid-column: 7/13;
  border-radius: 50%;
  aspect-ratio: 1/1;
  overflow: hidden;
  width: 100%;
  max-width: 140rem;
  position: absolute;
  right: -33rem;
  bottom: 5rem;
  z-index: 2;
  @media ${mq.desktopLarge} {
    right: -33rem;
  }
  @media (min-width: 1879px) {
    right: 0;
  }
  /* @media (min-width: 1025px) {
    top: -44rem;
  } */
  @media ${mq.tablet} {
    bottom: unset;
    // top: -23rem;
    top: -44rem;
    right: -13rem;
  }
  @media ${mq.mobile} {
    bottom: unset;
    top: -23rem;
    //right: -13rem;
    right: -29rem;
  }
  @media (max-width: 460px) {
    width: 40rem;
    bottom: unset;
    top: -15rem;
    right: -13rem;
  }
`

StyledFooter.Upper = styled.div`
  ${sharedGridStyles};
  z-index: 3;
`

StyledFooter.Bottom = styled.div`
  ${sharedGridStyles};
  //padding: 0;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  z-index: 3;
  ${StyledColumnItem} {
    padding: 0;
    padding-left: ${spacing.medium};
    @media ${mq.tablet} {
      padding-left: ${spacing.xSmall};
    }
  }

  @media ${mq.tablet} {
    row-gap: ${spacing.base};
    border-top: unset;
    padding: 0 ${spacing.small};
    ${StyledColumnItem} {
      &:first-child {
        grid-row: 1;
      }
    }
    ${StyledPaymentLogos} {
      width: 100%;
      grid-row: 2;
      grid-column: span 2;
      justify-content: center;
      padding: ${spacing.medium} 0;
      border-top: 1px solid rgb(255, 253, 250, 0.2);
    }
  }
`

StyledFooter.Social = styled.div`
  display: flex;
  gap: ${spacing.base};
  grid-column-start: 12;
  justify-self: end;
  align-self: center;
  padding-right: ${spacing.medium};
  @media ${mq.tablet} {
    grid-column: 2/2;
    grid-row: 1;
    padding-right: ${spacing.xSmall};
  }
`
export default StyledFooter
