import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import StyledColumnItem from './style'
import { Link } from '../../../components/Link'
import { createShopUrl, getUrl } from '../../../helpers/url'
import { ContentExpander } from '../../../components/ContentExpander'
import { Icon } from '../../../components/Icon'
import { COLORS } from '../../../helpers/constants'

const ColumnItem = props => {
  const {
    children,
    span = 1,
    list,
    items,
    // content,
    heading,
    expander,
    reverse,
    mobileBorder,
    align,
    hideInMobile,
    padding,
  } = props
  const [isOpen, setIsOpen] = useState(false)

  return (
    <StyledColumnItem
      span={span}
      isOpen={isOpen}
      reverse={reverse}
      mobileBorder={mobileBorder}
      align={align}
      hideInMobile={hideInMobile}
      padding={padding}
    >
      {heading && heading.length > 0 && (
        <StyledColumnItem.Heading onClick={() => setIsOpen(!isOpen)}>
          {heading}
          {expander && (
            <Icon icon={'angle-down'} size={24} color={COLORS.WHITE} />
          )}
        </StyledColumnItem.Heading>
      )}
      {list ? (
        <ContentExpander open={isOpen} duration={0.3}>
          <StyledColumnItem.List>
            {items &&
              items.map((item, key) => {
                const itemText = item.link?.title
                let itemUrl
                if (item.url) {
                  itemUrl = createShopUrl(item.url)
                } else {
                  itemUrl = getUrl(item.link?.url)
                }
                const underline = item.link?.underline ? true : false
                return itemUrl || item.link?.url ? (
                  <StyledColumnItem.ListItem key={key} underline={underline}>
                    {item.fieldGroup ? (
                      <>
                        {item.title && <p>{item.title}</p>}
                        {item.text && <span>{item.text}</span>}
                        {item.link.title && (
                          <Link to={item.link.url}>{item.link.title}</Link>
                        )}
                      </>
                    ) : (
                      <>
                        {itemText && itemUrl && (
                          <Link to={itemUrl}>{itemText}</Link>
                        )}
                      </>
                    )}
                  </StyledColumnItem.ListItem>
                ) : (
                  <Fragment key={key}></Fragment>
                )
              })}
          </StyledColumnItem.List>
        </ContentExpander>
      ) : expander ? (
        <ContentExpander open={isOpen} duration={0.3}>
          <StyledColumnItem.Content>{children}</StyledColumnItem.Content>
        </ContentExpander>
      ) : (
        <StyledColumnItem.Content>{children}</StyledColumnItem.Content>
      )}
    </StyledColumnItem>
  )
}

ColumnItem.propTypes = {
  children: PropTypes.any,
  handleClick: PropTypes.func,
  href: PropTypes.string,
  active: PropTypes.bool,
  expander: PropTypes.bool,
  reverse: PropTypes.bool,
  span: PropTypes.number,
  padding: PropTypes.bool,
  hideInMobile: PropTypes.bool,
  mobileBorder: PropTypes.bool,
  list: PropTypes.bool,
  align: PropTypes.string,
  items: PropTypes.array,
  heading: PropTypes.string,
}

export { ColumnItem }
