import styled, { css } from 'styled-components'
import { typography, mq, spacing } from '../../styles/utilities/variables'
import { StyledImageCardDiv } from '../ImageCard/style'
import StyledText from '../Text/style'

const StyledInteractiveHero = styled.div`
  height: 100%;
  position: relative;
  margin-bottom: ${spacing.large};
  margin-top: -${spacing.xxLarge};

  @media ${mq.tablet} {
    margin-top: -${spacing.xLarge};
  }

  @media ${mq.mobile} {
    margin-top: -${spacing.medium};
    margin-bottom: 0;
  }

  ${props =>
    props.overlay &&
    css`
      &:after {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        content: '';
        @media ${mq.desktopMin} {
          background: ${props =>
            props.background === true ? 'rgba(0, 0, 0, 0.25)' : 'none'};
        }
        height: 100%;
        width: 100%;
        z-index: -1;
      }
    `}

  ${StyledImageCardDiv} {
    text-align: center;
    background-color: transparent;

    @media ${mq.mobile} {
      min-height: 384px;
    }

    @media ${mq.mobileSmall} {
      min-height: 192px;
    }
  }

  ${StyledText} {
    line-height: 1.4;
  }

  @media ${mq.tablet} {
    ${StyledText} {
      display: none;
    }
  }

  p {
    font-family: ${typography.secondaryFont};
    color: ${props => (props.background === true ? '#ffffff' : '#000000')};
    font-size: 2.4rem;
    line-height: ${typography.lineHeights.medium};
    font-weight: bold;
    letter-spacing: -0.01em;
  }

  h1 {
    color: ${props => (props.background === true ? '#ffffff' : '#000000')};
    line-height: 1.15;
  }
`

export default StyledInteractiveHero
