import React from 'react'
import { setSpecialAccessCookie } from '../../helpers/url'
import { isSpecialAccess } from '../../helpers/url'
import { StyledSpecialAccessModal } from './style'
import { Button } from '../Button'
import { Form } from '../Form'
import { Heading } from '../Heading'
import { useForm } from 'react-hook-form'
import { getIsClient } from '../../helpers/utilities'

export const SpecialAccessModal = () => {
  const url = getIsClient() ? window.location.pathname : null
  const { register, handleSubmit } = useForm()

  /**
   * Handles the form submission for the SpecialAccessModal component.
   *
   * @param {Object} data - The form data.
   * @param {Event} event - The form submission event.
   */
  const onSubmit = (data, event) => {
    const inputValue = event.target[0].value
    if (inputValue === process.env.GATSBY_SPECIAL_ACCESS_TOKEN) {
      setSpecialAccessCookie()
    } else {
      alert('Fel lösenord')
    }
  }

  if (!isSpecialAccess()) {
    return null
  }

  return (
    <StyledSpecialAccessModal>
      <dialog data-modal open class='special-access-dialog'>
        <Heading size={4} type={'h4'}>
          Denna sida är för anställda på Newbody Family
        </Heading>
        <a href={'https://www.newbodyfamily.com'}>Gå till newbodyfamily.com</a>
        <Form onSubmit={handleSubmit(onSubmit)} center>
          <Form.Input
            {...register('specialAccessToken')}
            type='password'
            placeholder='Ange lösenord'
          ></Form.Input>
          <Button type='submit'>Skicka</Button>
        </Form>
      </dialog>
    </StyledSpecialAccessModal>
  )
}
