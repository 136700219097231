import styled from 'styled-components'

export const StyledSpecialAccessModal = styled.div`
  &:has(dialog) {
    z-index: 105;
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.9);
  }
  &:focus {
    outline: none;
  }
  a {
    font-size: 18px;
    color: #000;
    text-decoration: underline;
    &:focus-visible {
      outline: 1px solid #000;
      outline-offset: 2px;
    }
  }
  dialog {
    z-index: 110;
    padding: 4rem;
    position: fixed;
    top: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4rem;
    border-radius: 0.4rem;
  }
  button {
    margin-top: 1rem;
  }
`
