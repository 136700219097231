
/**
 * Image
 * @param {*} image 
 */
export const getImageUrl = image => {
  if (image && image.imageFile && Object.keys(image.imageFile).length > 0) {
    const sharp = image.imageFile.childImageSharp.fluid
      ? image.imageFile.childImageSharp.fluid
      : image.imageFile.childImageSharp.fixed
    if (sharp) {
      return sharp.srcWebp ? sharp.srcWebp : sharp.src
    }
  }
  if (image && image.url) return image.url
  return image && image.sourceUrl ? image.sourceUrl : ''
}

/**
 * Get preload image data
 * @param {*} image 
 */
export const getPreloadData = (image, size = 1600) => {
  const { webpUrl, srcSetWebp } = image
  if (!image || !webpUrl) return null
  return {
    href: webpUrl,
    imagesrcset: srcSetWebp.join(', '),
    imagesizes: `(max-width: ${size}px) 100vw, ${size}px`
  }
} 
