import React, { useState, useEffect, Fragment } from 'react'
import { StyledRecursiveMenuItem, StyledRecursiveMenu } from './style'
import { ContentExpander } from '../../../components/ContentExpander'
import { createShopUrl, getUrl } from '../../../helpers/url'
import { Icon } from '../../../components/Icon'

import { findMenuItem } from '../../../helpers/menu'
import { currentPathWithoutPrefix } from '../../../helpers/utilities'

const RecursiveMenuContainer = ({ children }) => {
  return <StyledRecursiveMenu>{children}</StyledRecursiveMenu>
}

const RecursiveMenu = props => {
  const { items, isTopLevel = true, expanderOpen } = props
  const [currentPage, setCurrentPage] = useState(currentPathWithoutPrefix())
  const [currentParent, setCurrentParent] = useState(null)

  /** Find toplevel item to check current parent item */
  useEffect(() => {
    if (isTopLevel) {
      const result = findMenuItem(items, 'url', currentPage)

      if (result) {
        setCurrentParent(result)
      }
      if (result?.parent) {
        setCurrentParent(result.parent)
      }
    }
  }, [currentPage])

  return (
    <Fragment>
      {Object.keys(items).map((key, index) => {
        const item = items[key]

        return (
          <StyledRecursiveMenuItem key={index}>
            <RecursiveMenuItem
              item={item}
              shopItems
              isTopLevel={isTopLevel}
              expanderOpen={expanderOpen}
              openParent={currentParent?.url === item.url ? true : false}
            />
          </StyledRecursiveMenuItem>
        )
      })}
    </Fragment>
  )
}

const RecursiveMenuItem = ({
  item,
  isTopLevel = true,
  expanderOpen = false,
  openParent,
}) => {
  const { label, url, text } = item
  const [isOpen, setIsOpen] = useState(false)
  let href

  /** Set contentExpander to open on current page parent link */
  useEffect(() => {
    if (isTopLevel) {
      setIsOpen(openParent)
    }
  }, [openParent])

  if (item.text) {
    // check if item has text(should only exist on shop items)
    href = createShopUrl(url)
  } else {
    href = getUrl(url)
  }
  const hasChildren =
    item?.childItems?.nodes.length > 0 || item?.children?.length > 0

  return (
    <Fragment>
      {hasChildren && (
        <StyledRecursiveMenuItem.Button
          onClick={() => setIsOpen(!isOpen)}
          isTopLevel={isTopLevel}
          open={isOpen}
        >
          {label || text}
          <Icon icon={'angle-down'} open={isOpen} />
        </StyledRecursiveMenuItem.Button>
      )}
      {!hasChildren && href && (text || label) && (
        <StyledRecursiveMenuItem.Link
          to={href}
          isTopLevel={isTopLevel}
          open={!hasChildren ? expanderOpen : false}
        >
          {label || text}
        </StyledRecursiveMenuItem.Link>
      )}
      {hasChildren && (
        <ContentExpander open={isOpen} border={isOpen}>
          <RecursiveMenu
            items={item.childItems?.nodes || item.children}
            isTopLevel={false}
            expanderOpen={isOpen}
          />
        </ContentExpander>
      )}
    </Fragment>
  )
}

export { RecursiveMenuContainer, RecursiveMenu, RecursiveMenuItem }
