import styled from 'styled-components'
import { mq, typography } from '../../../styles/utilities/variables'
import { colors } from '../../../styles/utilities/variables'

const StyledMetaTitle = styled.p`
  font-family: ${typography.secondaryFont};
  font-size: ${props =>
    props.bold ? typography.sizes.xxSmall : typography.sizes.small};
  line-height: ${typography.lineHeights.xSmall};
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
  text-transform: uppercase;
  color: ${props => (props.color ? colors[props.color] : colors.black)};
  letter-spacing: 0.5px;
  text-align: ${props => (props.center ? 'center' : 'left')};
  line-height: 1.3;

  @media ${mq.mobile} {
    font-size: ${props =>
      props.bold ? typography.sizes.xxxSmall : typography.sizes.xxSmall};
  }
`

export default StyledMetaTitle
