import styled, { css } from 'styled-components'
import { spacing, colors, typography, mq } from '../../styles/utilities/variables'
import StyledIcon from '../Icon/style'

const StyledLanguageSwitcher = styled.div`
  list-style: none;
  cursor: pointer;
  position: relative;
  margin-left:${spacing.base};
  @media ${mq.mobile} {
    display: none;
  }

  ${props => props.open && css`
    & > div {
      > span {
        color: ${colors.red};
      }

      > ${StyledIcon} > i {
        color: ${colors.red};
      }
    }
  `}
`


StyledLanguageSwitcher.Globe = styled.div`
  display: flex;
  align-items: center;

  > span {
    font-size: ${typography.sizes.xxSmall};
    color: ${props => (props.open ? colors.red : colors.white)};
    margin: 0 ${spacing.xxSmall};
  }

  & ${StyledIcon} {
    &:not(:first-child) {
      display: flex;
      align-items: center;
      transition: transform 0.3s;
      transform: ${props => (props.open ? 'rotate(180deg)' : '')};
    }
  }
`

StyledLanguageSwitcher.Container = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  transform: translateY(100%);
  padding-top: ${spacing.small};
  visibility: ${props => (props.open ? 'visible' : 'hidden')};
`

StyledLanguageSwitcher.List = styled.ul`
  background: ${colors.white};
  padding: ${spacing.base};
  color: ${colors.graphite};
  margin: 0;
  list-style: none;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
`

StyledLanguageSwitcher.ListItem = styled.li`
  text-align: left;
  white-space: nowrap;
  margin-bottom: ${spacing.base};
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }

  a {
    display: block;
    position: relative;
    color: ${colors.primary};
    font-size: ${typography.sizes.xxSmall};
    padding: ${spacing.small} ${spacing.medium};

    &:focus,
    &:active {
      color: ${colors.primary};
    }

    &:hover {
      color: ${colors.primary};
    }
  }

  & ${StyledIcon} {
    position: absolute;
    left: 0px;
    transform: translateY(-50%);
    top: 50%;

    > i {
      font-size: 10px;
    }
  }
`

StyledLanguageSwitcher.Mobile = styled.div`
`

StyledLanguageSwitcher.MobileList = styled.ul`
  padding-bottom: ${spacing.medium};
`

StyledLanguageSwitcher.MobileListItem = styled.li`
  display: flex;
  position: relative;

  > a {
    position: relative;
    color: ${colors.white};
    font-size: 1.4rem;
    font-family: ${typography.primaryFont};
    width: 100%;
    padding: 1rem 0;
    padding-left: ${spacing.large};

    & ${StyledIcon} {
      position: absolute;
      left: 0px;
      transform: translateY(-50%);
      top: 50%;

      > i {
        color: ${colors.white};
      }
    }
  }
`

StyledLanguageSwitcher.MobileGlobe = styled.button`
  background: transparent;
  border: none;
  padding: 0;
  width: 100%;
  display: flex;
  position: relative;
  color: ${colors.white};
  padding-left: 0;
  font-size: 1.4rem;
  line-height: 1.5;
  &:focus,
  &:active {
    border: none;
    outline: none;
  }

  & ${StyledIcon} {
    &:first-child {
      margin-right: 1.2rem;
    }

    &:last-child {
      margin: 0 0 0 auto;
      transform: rotate(${props => (props.open ? '180deg' : '0deg')});
      transition: transform 0.2s;
    }

    > i {
      color: ${colors.white};
    }
  }
`

export default StyledLanguageSwitcher

