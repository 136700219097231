import styled from 'styled-components'
import {
  colors,
  spacing,
  mq,
  maxWidths,
} from '../../../styles/utilities/variables'
import { Link as GatsbyLink } from 'gatsby'

export const StyledHeaderDropdown = styled.div`
  position: fixed;
  top: 11rem;
  left: 0;
  background: ${colors.black};
  color: ${colors.white};
  transition: all 0.2s ease-in-out;
  width: 100vw;
  z-index: 4;

  height: ${props => (props.isOpen ? '48px' : '0')};
  @media ${mq.tablet} {
    height: ${props => (props.isOpen ? '38.39px' : '0')};
  }
  ul {
    display: flex;
    align-items: center;
    gap: ${spacing.medium};
    list-style: none;
    max-width: ${maxWidths.wideContainer};
    margin: 0 auto;
  }
`
export const StyledHeaderDropdownListItem = styled.li`
  &:first-child {
    padding-left: ${spacing.large};
  }
  padding: ${spacing.base} 0;
  font-weight: ${props => (props.active ? '500' : 'normal')};
  a {
    text-decoration: none;
    color: ${colors.white};
  }
`

export const StyledHeaderDropdownLink = styled(GatsbyLink)``
