import styled, { css } from 'styled-components'
import { Link as GatsbyLink } from 'gatsby'
import {
  colors,
  spacing,
  typography,
  mq,
  headerHeights,
} from '../../styles/utilities/variables'
import StyledContainer from '../../components/Container/style'
import StyledMq from '../../styles/utilities/mq'
import StyledLanguageSwitcher from '../../components/LanguageSwitcher/style'
import StyledIcon from '../../components/Icon/style'

const StyledHeader = styled.header`
  position: sticky;
  top: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  height: ${headerHeights.desktop};
  background-color: ${colors.white};
  border-bottom: 1px solid ${colors.lightGrey};
  @media ${mq.mobile} {
   //height: ${headerHeights.mobile};
   height: 6rem;
  }
`

StyledHeader.Upper = styled.div`
  background-color: ${colors.primary};
  color: ${colors.white};
  z-index: 5;

  @media (max-width: 900px) {
    padding: ${spacing.xxSmall} 0;
    min-height: 4rem;
    display: flex;
    align-items: center;
    display: none;
  }

  & ${StyledContainer} {
    ::-webkit-scrollbar {
      display: none;
    }

    @media ${mq.mobile} {
      overflow-x: auto;
    }
  }
`

StyledHeader.Lower = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  z-index: 4;
  @media ${mq.mobile} {
    ${StyledContainer} {
      padding: 0 ${spacing.base};
    }
  }
`

StyledHeader.Information = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

StyledHeader.LeftSide = styled.ul`
  display: flex;
  align-items: center;
  gap: ${spacing.base};
  list-style: none;
`

StyledHeader.ListItem = styled.li`
  display: flex;
  position: relative;
  //gap: ${props => (props.currentParent ? '4px' : '')};

  svg {
    visibility: ${props => (props.current ? 'visible' : 'hidden')};
    opacity: ${props => (props.current ? 1 : 0)};
    transition: opacity 0.8s ease;
  }

  ${props =>
    props.currentParent &&
    css`
      svg {
        transition: unset;
        visibility: visible;
        opacity: 1;
      }
    `}
`

const sharedLinkStyling = () => css`
  cursor: pointer;
  font-size: ${typography.sizes.small};
  color: ${props => (props.color ? colors[props.color] : colors.white)};
  display: inline-flex;
  padding: ${spacing.base} 0;
  position: relative;
  align-items: center;
  font-weight: 500;
  transition: transform 0.4s ease;

  &[aria-current] {
    svg {
      visibility: visible;
      // transform: visibility 0.1s ease;
    }
  }
  ${StyledIcon} {
    margin-left: ${spacing.xxSmall};
  }

  @media ${mq.mobile} {
    padding: 1.3rem 0;
    white-space: nowrap;
  }
`
StyledHeader.InformationItem = styled(GatsbyLink)`
  ${sharedLinkStyling}
  ${props =>
    props.current &&
    css`
      transform: translateX(+4px);
    `}
     
  ${props =>
    props.currentParent &&
    css`
      transition: unset;
      transform: translateX(+4px);
    `}


  

  ${props =>
    props.visible &&
    css`
      svg {
        visibility: visible;
        opacity: 1;
      }
    `}
`
//cant use gatsby link on external links
StyledHeader.InformationItem.External = styled.a`
  ${sharedLinkStyling}
`

StyledHeader.Navigation = styled.div`
  display: flex;
  align-items: center;

  ${StyledMq.SmallTablet} {
    width: 100%;
  }
`

StyledHeader.RightSide = styled.div`
  display: flex;
  align-items: center;

  ${StyledLanguageSwitcher} {
    margin-left: 2rem;

    & span {
      margin: 0 ${spacing.xxSmall};
    }
  }

  .lockClosed {
    left: 0;
    top: 13px;
    position: absolute;

    @media ${mq.tablet} {
      top: 10px;
    }
  }

  ${StyledHeader.InformationItem.External} {
    padding-left: ${spacing.medium};
  }
`

export default StyledHeader

export const StyledCartHeader = styled.div`
  position: relative;
`

export const StyledLogotype = styled.div`
  @media ${mq.tablet} {
    .fullLogotypeNeg {
      width: 130px;
    }
  }

  @media ${mq.mobile} {
    max-width: 200px;
    svg {
      max-width: 200px;
    }
  }
`

export const StyledPrimary = styled.nav`
  display: flex;
  margin-right: auto;

  @media ${mq.smallTablet} {
    display: none;
  }
`

export const StyledSecondary = styled.nav`
  display: flex;
  align-items: center;
  gap: ${spacing.medium};

  .button {
    background-color: ${colors.nbSix};
    color: ${colors.black};
    padding: 1rem ${spacing.base};
  }
  @media ${mq.smallTablet} {
    display: none;
  }
`
