import React, { useState, useEffect } from 'react'
import StyledBallBounce from './style'
import PropTypes from 'prop-types'

const BallBounce = ({ number }) => {
  return <StyledBallBounce>{number}</StyledBallBounce>
}

BallBounce.propTypes = {
  number: PropTypes.string,
}

export { BallBounce }
