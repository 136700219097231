import styled from 'styled-components'
import { mq } from './variables'

const StyledMq = styled.div``

StyledMq.Desktop = styled.div`
  @media ${props =>
      props.mobile
        ? mq.mobile
        : props.smallTablet
        ? mq.smallTablet
        : mq.tablet} {
    display: none;
  }
`

StyledMq.Tablet = styled.div`
  display: none;
  @media ${mq.tablet} {
    display: block;
  }
`

StyledMq.SmallTablet = styled.div`
  display: none;
  @media ${mq.smallTablet} {
    display: block;
  }
`

StyledMq.Mobile = styled.div`
  display: none;
  @media ${mq.mobile} {
    display: block;
  }
`

export default StyledMq
