import styled, { css } from 'styled-components'
import {
  cssEasings,
  spacing,
  colors,
  mq,
  headerHeights,
} from '../../styles/utilities/variables'
import StyledOverlay from '../../components/Overlay/style'

const StyledSidebar = styled.aside`
  height: 100%;

  ${StyledOverlay} {
    z-index: 50;
  }
`

StyledSidebar.Slide = styled.div`
  width: 360px;
  max-width: 90%;
  position: fixed;
  top: ${headerHeights.desktop};
  left: 0;
  height: calc(100% - ${headerHeights.desktop});
  z-index: 60;
  flex-direction: column;
  background: #2e2d2d; // ${colors.white};
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: ${spacing.large};
  transform: translate(${props => (props.open ? '0' : '100%')});
  transition: transform 0.55s ${cssEasings.easeInOutQuart};
  display: none;
  
  @media ${mq.tablet} {
    display: flex;
    width: 100%;
    max-width: 100%;
    z-index: 52;
    top: 6rem;
    height: calc(100% - 6rem);
    padding: 0 ${spacing.base} ${spacing.large} ${spacing.base};
  }

  ${props =>
    props.level === 2 &&
    css`
      background: ${colors.darkGrey};
      transform: translateX(${props => (props.open ? '360px' : '-100%')});
      z-index: 56;
      opacity: ${props => (props.open ? 1 : 0)};
      transition: transform 0.3s ${cssEasings.easeInOutQuart}, opacity 0.3s;
      @media ${mq.tablet} {
        z-index: 56;
        transform: translateX(${props => (props.open ? '0' : '100%')});
      }
    `}
  ${props =>
    props.level === 3 &&
    css`
      transform: translateX(${props => (props.open ? '720px' : '-100%')});
      z-index: 52;
      opacity: ${props => (props.open ? 1 : 0)};
      transition: transform 0.3s ${cssEasings.easeInOutQuart}, opacity 0.3s;

      @media ${mq.tablet} {
        z-index: 60;
        transform: translateX(${props => (props.open ? '0' : '100%')});
      }
    `}
`

export default StyledSidebar
