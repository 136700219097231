import styled, { css } from 'styled-components'
import {
  typography,
  spacing,
  colors,
} from '../../../styles/utilities/variables'
import StyledIcon from '../../../components/Icon/style'
import { Link } from '../../../components/Link'

import StyledContentExpander from '../../../components/ContentExpander/style'

const sharedStyles = () => css`
  /* Alignment */
  display: flex;
  align-items: center;

  /* Font styles */
  color: ${colors.white};
  font-family: ${typography.secondaryFont};
  font-size: ${typography.sizes.small};
  font-weight: 500;

  /* Ground styles */
  padding: ${spacing.medium} ${spacing.xSmall};
  margin: 0;
  border: none;
  outline: none;
  background: transparent;

  &:not(:first-child) {
    margin-top: ${spacing.midBase};
  }

  &:focus,
  &:active {
    outline: none;
  }

  /* Icon styles */
  & ${StyledIcon} > i {
    color: ${colors.white};
  }

  ${props =>
    props.childItem &&
    css`
      font-size: ${typography.sizes.small};
      font-weight: 400;

      &:not(:first-child) {
        margin-top: ${spacing.base};
      }
    `}
`

const sharedTopLevelStyles = () => css`
  font-size: ${typography.sizes.base};
  font-weight: 600;
`
const StyledRecursiveMenu = styled.nav`
  border-top: 1px solid rgba(255, 255, 255, 0.1);
`

const StyledRecursiveMenuItem = styled.span`
  list-style: none;
`

StyledRecursiveMenuItem.Link = styled(Link)`
  ${sharedStyles}

  border-bottom: 1px solid transparent;
  ${props =>
    props.isTopLevel &&
    css`
      font-weight: 600;
      ${sharedTopLevelStyles}
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    `}
  ${props =>
    !props.isTopLevel &&
    css`
      padding: 1rem ${spacing.xSmall};
    `}
`

StyledRecursiveMenuItem.Button = styled.button`
  ${sharedStyles}
  width:100%;

  & ${StyledIcon} {
    margin-left: auto;
    transform: ${props => (props.open ? `rotate(180deg)` : `rotate(0deg)`)}; 
    transition: transform 0.2s linear;
  }
  border-bottom: 1px solid transparent;
  transition: border-color 0.2s ease;
  ${props =>
    props.isTopLevel &&
    css`
      ${sharedTopLevelStyles}
    `}
  ${props =>
    props.isTopLevel &&
    !props.open &&
    css`
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    `}
  ${props =>
    !props.isTopLevel &&
    css`
      &:not(:last-child) {
        padding: 1rem ${spacing.xSmall};
      }
    `}

  ${props =>
    props.iconBefore &&
    css`
      & ${StyledIcon} {
        margin-left: 0;
        margin-right: ${spacing.xSmall};
      }
    `}
`

StyledRecursiveMenuItem.Text = styled.div`
  ${sharedStyles}
  font-weight: bold;
  margin-top: ${spacing.base};
`
export { StyledRecursiveMenuItem, StyledRecursiveMenu }
