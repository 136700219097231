import styled, { css, createGlobalStyle } from 'styled-components'
import StyledButton from '../../components/Button/style'
import StyledIcon from '../../components/Icon/style'
import { colors } from '../../styles/utilities/variables'

export const DevelopmentBodyStyle = createGlobalStyle`
  body {
    padding-bottom:70px;
  }
`

export const StyledDevelopmentBranch = styled.aside`
  background: ${colors.red};
  color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 15px 30px;
  display: flex;
  z-index: 100;
  align-items: center;
  height: 70px;

  ${StyledButton} {
    margin-left: auto;
  }
`

StyledDevelopmentBranch.Title = styled.p`
  margin: 0;
  font-weight: bold;
`

StyledDevelopmentBranch.Action = styled.div`
  margin-left: auto;

  ${StyledIcon} {
    margin-left: 10px;

    ${props =>
      props.reload &&
      css`
        animation: rotate 1s infinite;
      `}
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

export default StyledDevelopmentBranch
