const findMenuItem = (array = [], searchKey = '', searchQuery = '') => {
  for (const item of array) {
    if (item[searchKey] === searchQuery) {
      return item
    } else {
      const childNodes = item?.childItems?.nodes

      if (childNodes?.length) {
        const result = findMenuItem(childNodes, searchKey, searchQuery)

        if (result) return { parent: item, result }
      }
    }
  }
}

const getMenuHandler = (value, match) => {
  const matchedItem = value
    .map(item => (item.slug === match ? item.menuItems.nodes : null))
    .filter(n => n)

  return matchedItem[0]
}

module.exports = {
  findMenuItem,
  getMenuHandler,
}
