import React from 'react'
import PropTypes from 'prop-types'
import StyledDivider from './style'

const Divider = props => {
  const debug = false
  return <StyledDivider debug={debug} {...props} />
}

Divider.propTypes = {
  size: PropTypes.string,
  border: PropTypes.bool,
  dots: PropTypes.string, 
  line: PropTypes.bool,
}

export { Divider }
